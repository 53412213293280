import "instant.page";
import "lazysizes";
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import "./components/block-links";

import SimpleLightbox from "simplelightbox";
import "simplelightbox/dist/simple-lightbox.min.css";

import "@fortawesome/fontawesome-pro";
import "@fortawesome/fontawesome-pro/js/solid";
import "@fortawesome/fontawesome-pro/js/regular";
import "@fortawesome/fontawesome-pro/js/brands";
import "@fortawesome/fontawesome-pro/js/duotone";

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.plugin(focus);

// Block scrolling when mobile navigation is open
Alpine.directive(
  "scroll-lock",
  (el, { expression }, { evaluateLater, effect }) => {
    let checkOpen = evaluateLater(expression);

    effect(() => {
      checkOpen((navOpen) => {
        if (navOpen) {
          if (window.scrollY) {
            window.scroll(0, 0); // reset the scroll position to the top left of the document.
          }
          disableBodyScroll(el);
        } else if (navOpen === false) {
          enableBodyScroll(el);
        }
      });
    });
  }
);

// Sets CSS var for header-height
Alpine.directive("header-measure", (el, { expression }, { evaluate }) => {
  let result = 0;
  let obs = new ResizeObserver(() => {
    let newResult = evaluate(expression);
    if (result === newResult) {
      return;
    } else {
      result = newResult;
      document.documentElement.style.setProperty(
        "--header-height",
        result + "px"
      );
    }
  });
  obs.observe(el);
});

// Anchor the burst to the first image of the homepage subhero
Alpine.directive("anchor-burst", (el) => {
  let burst = el.querySelector("#burst-accent");
  let topImage = el.querySelector("img");
  let obs = new ResizeObserver(() => {
    let topRect = topImage.getClientRects();
    let burstWidth = burst.clientWidth;
    let result = topRect[0].left + topRect[0].width / 2 - burstWidth / 2;
    burst.style.left = result + "px";
  });
  obs.observe(el);
});

Alpine.start();

var lightbox = new SimpleLightbox("[data-lightbox]", {
  download: 'Download image',
  showCounter: false,
  scrollZoom: false,
  nav: false
});